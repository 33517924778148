<template>
    <div>
        <b-container>
            <section class="reset-password">
                <div class="sector">
                    <p v-if="getError" class="custom-error mb-1"> {{ getError }} </p>
                    <p v-if="localError" class="custom-error mb-1"> {{ localError }} </p>
                    <form :class="getDisabled ? 'disabled-element' : ''" @submit.prevent="resetPassword" class="login-form">
                        <h2 class="form-title">Forgot your password?</h2>
                        <label class="form-text">Enter the e-mail address you use to sign in, and we’ll send you a link to reset your password.</label>
                        <div class="form-element">
                            <label for="email" class="form-label">E-mail address</label>
                            <input :disabled="getDisabled" v-model.trim="email" @blur="$v.email.$touch()" :class="[{'input-error' : $v.email.$error }, getDisabled ? 'disabled-element' : '' ]" id="email" class="form-input" type="email" placeholder="Enter e-mail address" />
                            <div class="mb-1" v-if="$v.email.$error">
                                <p v-if="!$v.email.required" class="custom-error">E-mail address is required</p>
                                <p v-else-if="!$v.email.email" class="custom-error">E-mail address is invalid</p>
                                <p v-else-if="!$v.email.maxLength" class="custom-error">E-mail address must not exceed 50 characters</p>
                            </div>
                        </div>
                        <button :disabled="getDisabled" class="submit-button" type="submit">Reset Password</button>
                    </form>
                </div>
            </section>
        </b-container>
    </div>
</template>

<script>
import {
  email,
  required,
  maxLength
} from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
export default {
  data: function () {
    return {
      email: '',
      localError: null
    }
  },
  validations: {
    email: {
      required: required,
      email: email,
      maxLength: maxLength(50)
    }
  },
  methods: {
    resetPassword () {
      this.$store.dispatch('setDisabled')
      this.$v.email.$touch()
      if (!this.$v.email.$invalid) {
        this.$store.dispatch('resetPassword', { email: this.email }).then(() => {
        })
          .catch(error => {
            this.localError = error.message
            this.$store.dispatch('clearDisabled')
          })
      } else {
        this.$store.dispatch('clearDisabled')
      }
    }
  },
  computed: {
    ...mapGetters([
      'getError',
      'getDisabled'
    ])
  },
  beforeCreate () {
    this.$store.dispatch('clearError')
    this.$store.dispatch('clearDisabled')
  }
}
</script>
